export const changelogs = [
  {
    "date": "2024-01-31",
    "html": "閉關中，即日起不接受何任何外包案件",
    "type": "log"
  },
  {
    "date": "2024-04-17",
    "html": "完成<a href='https://hackstep.pages.dev'>Hackstep</a>知識分享平台初稿"
  },
  {
    "date": "2024-05-31",
    "html": "更新<a href='https://we.alearn.org.tw/'>自學2.0</a>"
  },
  {
    "date": "2024-06-20",
    "html": "更新<a href='https://math.alearn.org.tw/'>自由數學</a>"
  },
  {
    "date": "2024-06-22",
    "html": "整理<a href='https://www.youtube.com/watch?v=mJha4ls1MfM&list=PLebzuoh5ZI3LrVduRDqLcxCxlkruyDL27' target='_blank' rel='noopener norefferer'>Freemath自由數學的Podcast頻道</a>"
  },
  {
    "date": "2024-07-10",
    "html": "更新技能點和知識結構",
    "r": "trace"
  },
  {
    "date": "2024-07-11",
    "html": "新增<a href='https://playback.bestian.tw' target='_blank' rel='noopener norefferer'>Playback劇場</a>"
  },
  {
    "date": "2024-07-16",
    "html": "新增「贊助創作」頁面",
    "r": "sponsor"
  },
  {
    "date": "2024-07-16",
    "html": "製作Vue知識結構初稿",
    "r": "trace_vue"
  },
  { "date": "2024-09-17",
    "html": "更新技能點",
    "r": "courses"
  },
  {
    "date": "2024-11-11",
    "html": "更新<a href='https://map.alearn.org.tw' target='_blank' rel='noopener norefferer'>自學地圖，加入AI自學對話機器人</a>"
  },
  {
    "date": "2024-11-27",
    "html": "新增<a href='https://lin.ee/CashU46' target='_blank' rel='noopener norefferer'>自學AI Line Bot</a>"
  },
  {
    "date": "2024-12-08",
    "html": "新增<a href='https://xikxik.bestian.tw/#/' target='_blank' rel='noopener norefferer'>小道小報</a>並加入朗讀功能"
  },
  {
    "date": "2024-12-14",
    "html": "首頁新增「培力工程」項目，整理培力工程和近期學生作品"
  },
  {
    "date": "2024-12-26",
    "html": "新增codePen和Github教學說明",
    "r": "course"
  },
  {
    "date": "2024-12-29",
    "html": "首頁改版"
  },
  {
    "date": "2025-01-29",
    "html": "新增〈數理思維和設計思維〉文章",
    "r": "articles"
  },
  {
    "date": "2025-02-13",
    "html": "新增<a href='https://hackmd.io/@bestian/S12gYAcFke#/' target='_blank' rel='noopener norefferer'>泛用性AI工具的簡介</a>"
  }
]
